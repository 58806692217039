import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { PrimaryBtn } from 'components/UI/Buttons';
import { useGetFollowUpsQuery } from 'services/api/followUpConfig.api';

import FollowUpConfigForm from './FollowUpConfigForm';

const FollowUpConfig = () => {
  const { dealershipId } = useParams();
  const navigate = useNavigate();

  if (!dealershipId) {
    return <div>Dealership id not found</div>;
  }
  const followUps = useGetFollowUpsQuery(Number(dealershipId));

  return (
    <Box pl={1}>
      <Box display="flex" justifyContent="flex-start" mb={2} alignItems="center">
        <Typography variant="h3">Follow Ups</Typography>
        <PrimaryBtn sx={{ ml: 2 }} onClick={() => navigate(-1)} variant="contained">
          Volver
        </PrimaryBtn>
      </Box>

      {followUps.data?.map((followUp) => <FollowUpConfigForm key={followUp.id} followUpConfig={followUp} />)}
    </Box>
  );
};

export default FollowUpConfig;

import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { BarChart } from '@mui/x-charts/BarChart';
import { LineChart } from '@mui/x-charts/LineChart';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

import { PrimaryBtn } from 'components/UI/Buttons';
import useIsAdmin from 'hooks/useIsAdmin';
import moment from 'moment';
import {
  useAdminGetDealershipCostChartsQuery,
  useGetDealershipQuery,
  useAdminGetDealershipConversationTopicsMutation,
} from 'services/api/dealerships.api';
import priceFormat from 'services/format/priceFormat';

import ChartCard from '../Dashboard/components/ChartCard';

const DealershipCharts = () => {
  const { dealershipId } = useParams();
  const { data: dealership } = useGetDealershipQuery(Number(dealershipId));
  const { data: dealershipCostsCharts } = useAdminGetDealershipCostChartsQuery(Number(dealershipId));
  const [getTopics, { data: conversationTopics }] = useAdminGetDealershipConversationTopicsMutation();

  const currentDate = new Date();
  const dateBeginningOfMonth = moment(new Date(currentDate.getFullYear(), currentDate.getMonth(), 1));
  const dateEndOfMonth = moment(new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1));
  const [initialDate, setInitialDate] = React.useState<moment.Moment>(dateBeginningOfMonth);
  const [endDate, setEndDate] = React.useState<moment.Moment>(dateEndOfMonth);

  const isAdmin = useIsAdmin();
  const navigate = useNavigate();

  useEffect(() => {
    getTopics({
      dealershipId: Number(dealershipId),
      startDate: initialDate.toISOString(),
      endDate: endDate.toISOString(),
    }).catch(
      (err) => console.log(err) // eslint-disable-line
    );
  }, [initialDate, endDate]);

  if (!dealership || !isAdmin) {
    return <Typography variant="h3">No se encontró la empresa</Typography>;
  }

  const htmlPrompt = dealership.prompt
    .replaceAll('>', '&gt;')
    .replaceAll('<', '&lt;')
    .replaceAll('\n', '<br />')
    .replaceAll(/&lt;\S*?[^_]&gt;/g, (str) => `<span style="color: red;">${str}</span>`)
    .replaceAll(/&lt;!--[\S\s]*?--&gt;/g, (str) => `<span style="color: grey;">${str}</span>`)
    .replaceAll(/https[^\n\r<]+/g, (str) => `<span style="color: blue; text-decoration: underline">${str}</span>`)
    .replaceAll(/#+\s[^\n\r<]+/g, (str) => `<span style="font-weight: bold;">${str}</span>`);

  // eslint-disable-next-line react/no-danger
  const prettyPrompt = <div id="dummy" className="original" dangerouslySetInnerHTML={{ __html: htmlPrompt }} />;

  return (
    <Box pl={1}>
      <Box display="flex" justifyContent="flex-start" mb={2} alignItems="center">
        <Typography variant="h3">Empresa</Typography>
        <PrimaryBtn sx={{ ml: 2 }} onClick={() => navigate(-1)} variant="contained">
          Volver
        </PrimaryBtn>
      </Box>
      <Typography variant="body1">
        <b>Nombre:</b> {dealership?.name}
      </Typography>
      <Typography variant="body1">
        <b>Teléfono:</b> {dealership?.phoneNumber}
      </Typography>
      <Typography variant="body1">
        <b>WhatsApp Tiers:</b>{' '}
        {dealership?.phoneNumberTier?.map((tier) => `${tier.split('T')[0]} ${tier.split(':').at(-1)}`).join(', ')}
      </Typography>

      <Typography variant="body1">
        <b>Costo total:</b>{' '}
        {priceFormat.format(
          Object.values(dealershipCostsCharts?.costPerDay || {}).reduce((acc, curr) => acc + curr, 0)
        )}
      </Typography>
      <Typography variant="body1">
        <b>Costo de FloidRequests:</b> {priceFormat.format(dealershipCostsCharts?.totalFloidRequestsCost || 0)}
      </Typography>
      <ChartCard
        title="Costos por cliente en el tiempo"
        description="Costo promedio de los clientes creados un día en específico (WhatsApp, AI y floid)"
      >
        <LineChart
          series={[
            {
              data: Object.values(dealershipCostsCharts?.averageCostPerDay || {}),
            },
          ]}
          height={290}
          xAxis={[
            {
              data: Object.keys(dealershipCostsCharts?.averageCostPerDay || {}),
              scaleType: 'band',
              tickLabelStyle: {
                angle: 45,
                textAnchor: 'start',
                fontSize: 12,
              },
            },
          ]}
          yAxis={[
            {
              label: 'Costo ($)',
              min: 0,
              tickMinStep: 50,
            },
          ]}
          margin={{ top: 10, bottom: 60, left: 40, right: 80 }}
          title="Costos por cliente en el tiempo"
        />
      </ChartCard>

      <ChartCard
        title="Costos promedio de un mensaje por dia"
        description="Costo promedio de un mensaje por dia (WhatsApp, AI y floid). No se consideran mensajes no enviados"
      >
        <LineChart
          series={[
            {
              data: Object.values(dealershipCostsCharts?.averageMessageCostPerDay || {}),
            },
          ]}
          height={290}
          xAxis={[
            {
              data: Object.keys(dealershipCostsCharts?.averageMessageCostPerDay || {}),
              scaleType: 'band',
              tickLabelStyle: {
                angle: 45,
                textAnchor: 'start',
                fontSize: 12,
              },
            },
          ]}
          yAxis={[
            {
              label: 'Costo por mensaje ($)',
              min: 0,
              tickMinStep: 10,
            },
          ]}
          margin={{ top: 10, bottom: 60, left: 40, right: 80 }}
          title="Costos por mensaje en el tiempo"
        />
      </ChartCard>

      <ChartCard
        title="Costos por cliente"
        description="Cantidad de clientes que nos cuestan cada uno de estos rangos (WhatsApp, AI y floid)"
      >
        <BarChart
          series={[
            {
              data: (dealershipCostsCharts?.costRangesCount || []).map((costRange) => costRange.count),
            },
          ]}
          height={290}
          xAxis={[
            {
              data: (dealershipCostsCharts?.costRangesCount || []).map(
                (costRange) => `<${priceFormat.format(costRange.range[1])}`
              ),
              scaleType: 'band',
            },
          ]}
          yAxis={[
            {
              label: 'Cantidad de clientes',
              min: 0,
              tickMinStep: 1,
            },
          ]}
          margin={{ top: 10, bottom: 90, left: 40, right: 80 }}
          title="Costos promedio"
        />
      </ChartCard>

      <Typography variant="h6" py="25px">
        Filtrar fechas de estadísticas:
      </Typography>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <DatePicker
          label="Fecha inicial"
          format="DD/MMM/YYYY"
          value={initialDate}
          onChange={(value) => setInitialDate(value || dateBeginningOfMonth)}
          slotProps={{ textField: { variant: 'outlined' } }}
        />
        <DatePicker
          label="Fecha final"
          format="DD/MMM/YYYY"
          value={endDate}
          onChange={(value) => setEndDate(value || dateEndOfMonth)}
          slotProps={{ textField: { variant: 'outlined' } }}
        />
      </LocalizationProvider>
      <ChartCard
        title="Temas de conversación"
        description="Temas hablados por los clientes. Puede haber más de un tema por cliente (conversación)"
      >
        <BarChart
          series={[
            {
              data: conversationTopics ? Object.values(conversationTopics.topics) : [],
            },
          ]}
          height={290}
          xAxis={[
            {
              data: conversationTopics ? Object.keys(conversationTopics.topics) : [],
              scaleType: 'band',
            },
          ]}
          yAxis={[
            {
              label: 'Cantidad de conversaciones',
              min: 0,
              tickMinStep: 1,
            },
          ]}
          margin={{ top: 10, bottom: 90, left: 40, right: 80 }}
        />
      </ChartCard>
      <ChartCard title="Motivos de término" description="Motivos de término de conversaciones">
        <BarChart
          series={[
            {
              data: conversationTopics ? Object.values(conversationTopics.endReasons) : [],
            },
          ]}
          height={290}
          xAxis={[
            {
              data: conversationTopics ? Object.keys(conversationTopics.endReasons) : [],
              scaleType: 'band',
            },
          ]}
          yAxis={[
            {
              label: 'Cantidad de conversaciones',
              min: 0,
              tickMinStep: 1,
            },
          ]}
          margin={{ top: 10, bottom: 90, left: 40, right: 80 }}
        />
      </ChartCard>
      <ChartCard title="Puntajes de conversaciones" description="Puntajes al vendedor, obtenido con AI">
        <BarChart
          series={[
            {
              data: conversationTopics ? Object.values(conversationTopics.aiScores) : [],
            },
          ]}
          height={290}
          xAxis={[
            {
              data: conversationTopics ? Object.keys(conversationTopics.aiScores) : [],
              scaleType: 'band',
            },
          ]}
          yAxis={[
            {
              label: 'Cantidad de conversaciones',
              min: 0,
              tickMinStep: 1,
            },
          ]}
          margin={{ top: 10, bottom: 90, left: 40, right: 80 }}
        />
      </ChartCard>

      <Typography variant="body1" mt={2}>
        <b>Prompt más bonito:</b>
      </Typography>
      <Box px={4}>{prettyPrompt}</Box>
    </Box>
  );
};

export default DealershipCharts;

import React, { FC, useEffect, useState } from 'react';

import EditIcon from '@mui/icons-material/Edit';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import AlertDialog from 'components/UI/AlertDialog';
import { PrimaryBtn } from 'components/UI/Buttons';
import InfoDialog from 'components/UI/InfoDialog';
import EditableAttribute from 'pages/Client/EditableAttribute';
import { useEditFollowUpMutation } from 'services/api/followUpConfig.api';
import type { FollowUpConfig } from 'services/types/followUpConfig';

interface FollowUpConfigFormProps {
  followUpConfig: FollowUpConfig;
}

const FollowUpConfigForm: FC<FollowUpConfigFormProps> = ({ followUpConfig }) => {
  const [updateFollowUpConfig, { isError, error, isSuccess }] = useEditFollowUpMutation();
  const [conditionsError, setConditionsError] = useState(false);
  const [templateError, setTemplateError] = useState(false);

  const [followUpConfigDraft, setFollowUpConfigDraft] = useState<FollowUpConfig>({
    ...followUpConfig,
    conditionsToSend: JSON.stringify(followUpConfig.conditionsToSend),
    templateComponents: JSON.stringify(followUpConfig.templateComponents),
  });

  const [isEditing, setIsEditing] = useState(false);
  const [isUpdateError, setIsUpdateError] = useState(false);

  const onChangeConditionsToSend = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newConditions = e.target.value;
    try {
      const value = JSON.parse(newConditions) as string;
      setFollowUpConfigDraft({ ...followUpConfigDraft, conditionsToSend: JSON.stringify(value, null, 2) });
      setConditionsError(false);
    } catch (_e) {
      setFollowUpConfigDraft({ ...followUpConfigDraft, conditionsToSend: newConditions });
      setConditionsError(true);
    }
  };

  const onChangeTemplateComponents = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newTemplateComponents = e.target.value;
    try {
      const value = JSON.parse(newTemplateComponents) as string;
      setFollowUpConfigDraft({ ...followUpConfigDraft, templateComponents: JSON.stringify(value, null, 2) });
      setTemplateError(false);
    } catch (_e) {
      setFollowUpConfigDraft({ ...followUpConfigDraft, templateComponents: newTemplateComponents });
      setTemplateError(true);
    }
  };

  useEffect(() => {
    if (isError) {
      setIsUpdateError(true);
    }
  }, [isError]);

  return (
    <Box padding={2} margin={2} border={followUpConfig.isActive ? 'black solid' : 'red solid'} borderRadius={2}>
      <AlertDialog
        openDialog={isUpdateError}
        setOpenDialog={setIsUpdateError}
        header="Error al editar follow up"
        msg={JSON.stringify(error)}
        cancelMsg="Cerrar"
      />
      {isSuccess && <InfoDialog header="Follow up actualizado!" msg="Follow up fue actualizado correctamente" />}
      <PrimaryBtn onClick={() => setIsEditing(!isEditing)} startIcon={<EditIcon />} variant="contained">
        {isEditing ? 'Cancelar' : 'Editar'}
      </PrimaryBtn>
      {isEditing && (
        <PrimaryBtn
          onClick={() => {
            updateFollowUpConfig({
              ...followUpConfigDraft,
              conditionsToSend: JSON.parse(followUpConfigDraft.conditionsToSend) as string,
              templateComponents: JSON.parse(followUpConfigDraft.templateComponents) as string,
            }).catch((err) => console.error(err)); // eslint-disable-line no-console
            setIsEditing(false);
          }}
          startIcon={<EditIcon />}
          variant="contained"
        >
          Guardar
        </PrimaryBtn>
      )}
      <EditableAttribute
        label="Identificador"
        value={followUpConfigDraft.identifier}
        onChange={(e) => setFollowUpConfigDraft({ ...followUpConfigDraft, identifier: e.target.value })}
        disabled={!isEditing}
        type="text"
      />
      <EditableAttribute
        label="Activo"
        value={followUpConfigDraft.isActive}
        onChange={(e) => setFollowUpConfigDraft({ ...followUpConfigDraft, isActive: e.target.checked })}
        disabled={!isEditing}
        type="checkbox"
      />

      <Grid item xs={12} px={1} pt={1}>
        <Typography>
          <b>
            {followUpConfig.prompt && 'Prompt'}
            {followUpConfig.specificTexts && 'Textos específicos'}
            {followUpConfig.templateName && 'Template'}:
          </b>{' '}
          {followUpConfig.prompt || followUpConfig.specificTexts?.join(', ') || followUpConfig.templateName}
        </Typography>
      </Grid>

      <Box display="flex" justifyContent="inline-flex">
        <EditableAttribute
          label="Min wait time"
          value={followUpConfigDraft.minMinutesWaitTime}
          onChange={(e) =>
            setFollowUpConfigDraft({ ...followUpConfigDraft, minMinutesWaitTime: Number(e.target.value) })
          }
          disabled={!isEditing}
          type="number"
        />
        <EditableAttribute
          label="Max wait time"
          value={followUpConfigDraft.maxMinutesWaitTime}
          onChange={(e) =>
            setFollowUpConfigDraft({ ...followUpConfigDraft, maxMinutesWaitTime: Number(e.target.value) })
          }
          disabled={!isEditing}
          type="number"
        />
      </Box>
      <Box display="flex" justifyContent="inline-flex">
        <EditableAttribute
          label="Min working hour"
          value={followUpConfigDraft.minWorkingHour}
          onChange={(e) => setFollowUpConfigDraft({ ...followUpConfigDraft, minWorkingHour: Number(e.target.value) })}
          disabled={!isEditing}
          type="number"
        />
        <EditableAttribute
          label="Max working hour"
          value={followUpConfigDraft.maxWorkingHour}
          onChange={(e) => setFollowUpConfigDraft({ ...followUpConfigDraft, maxWorkingHour: Number(e.target.value) })}
          disabled={!isEditing}
          type="number"
        />
      </Box>

      <EditableAttribute
        label="Enviar fin de semana"
        value={followUpConfigDraft.sendOnWeekend}
        onChange={(e) => setFollowUpConfigDraft({ ...followUpConfigDraft, sendOnWeekend: e.target.checked })}
        disabled={!isEditing}
        type="checkbox"
      />
      <EditableAttribute
        label="Max consecutivos"
        value={followUpConfigDraft.maxConsecutive}
        onChange={(e) => setFollowUpConfigDraft({ ...followUpConfigDraft, maxConsecutive: Number(e.target.value) })}
        disabled={!isEditing}
        type="number"
      />
      <EditableAttribute
        label="Max en conversación"
        value={followUpConfigDraft.maxAmountInConversation}
        onChange={(e) =>
          setFollowUpConfigDraft({ ...followUpConfigDraft, maxAmountInConversation: Number(e.target.value) })
        }
        disabled={!isEditing}
        type="number"
      />
      <EditableAttribute
        label="Previous Keep Alive Identifier"
        value={followUpConfigDraft.previousMessageKeepAliveIdentifier || ''}
        onChange={(e) =>
          setFollowUpConfigDraft({
            ...followUpConfigDraft,
            previousMessageKeepAliveIdentifier: e.target.value === '' ? null : e.target.value,
          })
        }
        disabled={!isEditing}
        type="text"
      />
      <EditableAttribute
        label="Required previous follow ups ids"
        value={followUpConfigDraft.requiredPreviousFollowUpsIds?.join(',') || ''}
        onChange={(e) =>
          setFollowUpConfigDraft({
            ...followUpConfigDraft,
            requiredPreviousFollowUpsIds: e.target.value === '' ? null : e.target.value.split(',').map(Number),
          })
        }
        disabled={!isEditing}
        type="text"
      />

      <Grid item xs={12} px={1} pt={1}>
        <Box>
          <Typography>
            <b>Conditions to send:</b>
          </Typography>
          <textarea
            name="conditionsToSend"
            disabled={!isEditing}
            rows={5}
            cols={90}
            onChange={(e) => onChangeConditionsToSend(e)}
            value={followUpConfigDraft.conditionsToSend}
            style={{
              marginBottom: '8px',
              backgroundColor: conditionsError ? '#e1c0c0' : '#c9d9c9',
              fontSize: '12px',
            }}
          />
        </Box>
        <Box>
          <Typography>
            <b>Template components:</b>
          </Typography>
          <textarea
            name="templateComponents"
            disabled={!isEditing}
            rows={5}
            cols={90}
            onChange={(e) => onChangeTemplateComponents(e)}
            value={followUpConfigDraft.templateComponents}
            style={{
              marginBottom: '8px',
              backgroundColor: templateError ? '#e1c0c0' : '#c9d9c9',
              fontSize: '12px',
            }}
          />
        </Box>
      </Grid>
    </Box>
  );
};

export default FollowUpConfigForm;

import React, { FC } from 'react';

import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import ListSubheader from '@mui/material/ListSubheader';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';

import { useGetUserDealershipsQuery } from 'services/api/user.api';

interface Props {
  dealershipId: number | null;
  setDealershipId: (dealershipId: number) => void;
  disableAllDealershipsOption?: boolean;
}

const DealershipSelector: FC<Props> = ({ dealershipId, setDealershipId, disableAllDealershipsOption }) => {
  const { data: dealerships } = useGetUserDealershipsQuery(null);

  if (!dealerships) {
    return null;
  }

  const dealershipByCompany = dealerships.reduce(
    (acc, dealership) => {
      const company = dealership.company.name;
      if (!acc[company]) {
        acc[company] = [];
      }
      acc[company].push(dealership);
      return acc;
    },
    {} as Record<string, typeof dealerships>
  );
  const menuItems = Object.keys(dealershipByCompany)
    .sort((a, b) => a.localeCompare(b))
    .map((company) => {
      // Need array render because you cant use a fragment inside a select
      return [
        <ListSubheader key={company} sx={{ textAlign: 'center', backgroundColor: '#f0f0f0' }}>
          <Typography fontWeight={500}>{company}</Typography>
        </ListSubheader>,
        dealershipByCompany[company].map((dealership) => (
          <MenuItem key={dealership.id} value={dealership.id}>
            <Typography fontWeight={500}>{dealership.name}</Typography>
          </MenuItem>
        )),
      ];
    });

  return (
    <FormControl sx={{ minWidth: 250, paddingRight: 1 }}>
      <InputLabel id="dealership-select-label">Unidad de negocio</InputLabel>
      <Select
        label="Unidad de negocio"
        variant="outlined"
        onChange={(e) => setDealershipId(Number(e.target.value))}
        value={dealershipId}
        autoWidth
      >
        {!disableAllDealershipsOption && <MenuItem value="-1">Todas las unidades de negocio</MenuItem>}
        {menuItems}
      </Select>
    </FormControl>
  );
};

DealershipSelector.defaultProps = {
  disableAllDealershipsOption: false,
};

export default DealershipSelector;
